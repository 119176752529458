import { render, staticRenderFns } from "./index.vue?vue&type=template&id=05b33ef6&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=05b33ef6&prod&scoped=true&lang=css"
import style1 from "./index.vue?vue&type=style&index=1&id=05b33ef6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b33ef6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalAlert: require('/app/components/modal/alert.vue').default})
